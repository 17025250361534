import React from "react";

export const Info = (props) => {
  return (
    <div id="info" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <h2>Congress Info</h2>
        </div>
        <div className="col-md-10 col-md-offset-1 section-title">
          <h3>Important Dates</h3>
        </div>

        <div class="row justify-xs-content-center">
          <div class="col-auto">
            <table class="table table-bordered text-center table-dates">
              <thead>
                <tr>
                  <th scope="col">DEADLINE</th>
                  <th scope="col">DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    Opening of registration and abstract submission
                  </th>
                  <td>1 February 2025</td>
                </tr>
                <tr>
                  <th scope="row">Submission of abstracts</th>
                  <td>31 May 2025</td>
                </tr>
                <tr>
                  <th scope="row">Notification of acceptance</th>
                  <td>10 June 2025</td>
                </tr>
                <tr>
                  <th scope="row">Early bird registration</th>
                  <td>30 June 2025</td>
                </tr>
                <tr>
                  <th scope="row">Preliminary program</th>
                  <td>15 July 2025</td>
                </tr>
                <tr>
                  <th scope="row">Late registration</th>
                  <td>31 August 2025</td>
                </tr>
                <tr>
                  <th scope="row">Submission of full paper</th>
                  <td>8 September 2025</td>
                </tr>
                <tr>
                  <th scope="row">Final program</th>
                  <td>12 September 2025</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-10 col-md-offset-1 section-title">
          <h3>Proceedings</h3>
        </div>
        <div className="col-md-8 col-md-offset-2 text-justify-xs">
          <div className="info-text">
            <p>
              The proceedings will be posted on the congress website within two
              weeks after the conference and will contain only the full papers
              that have been presented at the congress.
            </p>
          </div>
        </div>
        <div className="col-md-10 col-md-offset-1 section-title">
          <h3>Registration Fees</h3>
        </div>
        <div className="col-md-8 col-md-offset-2 text-justify-xs">
          <div className="info-text">
            <p>
              Depending on the type of participant, different fees have been
              established and are summarized in the following table. Each
              congress participant is entitled to present 1 paper with a single
              paid congress fee - for each additional paper, an additional fee
              has to be paid.
            </p>
          </div>
        </div>
        <div className="col-md-8 col-md-offset-2">
          <table class="table table-bordered text-center">
            <thead>
              <tr>
                <th scope="col">PARTICIPANT</th>
                <th scope="col">Early bird (up to 30 June 2025)</th>
                <th scope="col">Late (from 1 July to 31 August 2025)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">AAAA and EAA member</th>
                <td>200 Euros</td>
                <td>240 Euros</td>
              </tr>
              <tr>
                <th scope="row">Non AAAA/EAA member</th>
                <td>240 Euros</td>
                <td>280 Euros</td>
              </tr>
              <tr>
                <th scope="row">Students*</th>
                <td>150 Euros</td>
                <td>180 Euros</td>
              </tr>
              <tr>
                <th scope="row">Additional paper</th>
                <td>40 Euros</td>
                <td>50 Euros</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-8 col-md-offset-2 text-justify-xs">
          <div className="info-text">
            <p>
              * Students are required to provide proof of current enrolment in a
              Bachelor, Master or Ph.D. Study program.
            </p>{" "}
            <br></br>
            <p>
              The congress fee is to be paid as indicated in the table above.
            </p>
            <p>
              Payment is a prerequisite for participation in the AAAA 2025
              congress.
            </p>
            <p>
              The congress fee is invoiced without VAT as the Croatian
              Acoustical Society is not registered for VAT according to Croatian
              legislation.
            </p>
          </div>
        </div>
        <div className="col-md-10 col-md-offset-1 section-title">
          <h3>Payment Procedure</h3>
        </div>
        <div className="col-md-8 col-md-offset-2 text-justify-xs">
          <div className="info-text">
            <p>The payment of the congress fee may be made by Bank Transfer.</p>
            <p>
              Please note that all bank transfer costs must be covered by the
              conference participant.
            </p>
            <br></br>
          </div>
        </div>
        <div className="col-md-10 col-md-offset-1 section-title">
          <h3>Refunds</h3>
        </div>
        <div className="col-md-8 col-md-offset-2 text-justify-xs">
          <div className="info-text">
            <p>
              Registration fees are refundable only until September 1, 2025.
            </p>
            <p>No refunds are available for non-attendance.</p>
            <p>
              To receive a refund, you should email a cancellation letter to the
              organizers before September 1, 2025.
            </p>
            <p>
              A cancellation fee of 20% of the registration fee will be charged
              to cover administrative costs.
            </p>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  );
};
