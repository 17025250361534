import React from "react";

export const Welcome = (props) => {
  return (
    <div id="welcome" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <h2>Welcome</h2>
        </div>
        <div className="logo">
          {" "}
          <img src="img/logoR.png" className="logo-responsive" alt="" />{" "}
        </div>
        <br></br>
        <div className="col-md-8 col-md-offset-2 text-justify-xs">
          <div className="welcome-text">
            <p>
              Welcome to the 11th Congress of the Alps Adria Acoustics
              Association (AAAA 2025)! The congress will be held in Varaždin,
              Croatia, on 18 and 19 September 2025.
            </p>
            <p>
              The Alps Adria Acoustics Association (AAAA) was founded by the
              acoustical societies of Austria, Croatia and Slovenia in 2002 as a
              new regional association. In 2019 the Hungarian Scientific Society
              for Optics, Acoustics, Motion Picture and Theatre Technology
              (OPAKFI) replaced the Austrian Acoustics Association as the new
              member of the AAAA. The original goal of the AAAA was to promote
              all aspects of research in the field of acoustics in the region.
              In addition, the aim was to improve the overall cooperation among
              the countries and their respective national societies.
            </p>
            <p>
              Every two years, one of the three member societies of the AAAA
              organizes a scientific congress on acoustics. The main goal of
              these congresses is to bring together acousticians from Croatia,
              Hungary and Slovenia, as well as from the other European
              countries, to exchange knowledge, share research outcomes and
              strengthen cooperation among these societies for the benefit of
              the whole region. The last event took place in Izola, Slovenia in
              2023.
            </p>
            <p>
              The AAAA 2025 is organized by the Acoustical Society of Croatia
              (HAD), acting on behalf of the Alps Adria Acoustics Association.
            </p>
            <p>
              During congress days, national and international experts will be
              given the opportunity to present scientific and applied papers on
              their research and professional activities in all fields of
              acoustics. Eminent international experts have been invited as
              keynote lecturers to present the latest developments in their
              respective fields.
            </p>
            <p>
              The AAAA 2025 covers a broad range of topics that are the focus of
              interest in the scientific community and among researchers working
              in the industry. Congress topics include (but are not limited to)
              architectural and building acoustics, auditory and speech
              acoustic, environmental and transportation noise, machinery noise
              and vibration control, computational acoustics, electroacoustics,
              legislation in acoustics, musical acoustics, measurement
              techniques, nonlinear acoustics, psychoacoustics and perception of
              sound, soundscape, signal processing, sound generation and
              radiation, ultrasound, hydroacoustics, and many others.
            </p>
            <p>Looking forward to meeting you in Varaždin in September 2025!</p>
          </div>
        </div>
      </div>
    </div>
  );
};
