import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Intro } from "./components/intro";
import { Welcome } from "./components/welcome";
// import { Registration } from "./components/registration";
import { Info } from "./components/info";
import { Venue } from "./components/venue";
import { Contact } from "./components/contact";
import { Keynote } from "./components/keynote";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Intro data={landingPageData.Intro} />
      <Welcome data={landingPageData.Welcome} />
      <Venue data={landingPageData.Venue} />
      <Info data={landingPageData.Info} />
      <Keynote data={landingPageData.Keynote} />
      {/* <Registration data={landingPageData.Registration} /> */}
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
