import React from "react";

export const Intro = (props) => {
  return (
    <intro id="intro">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  The 11th
                  <br></br>
                  Congress of
                  <br></br>
                  the Alps Adria Acoustics Association
                  <span></span>
                </h1>
                <h2>
                  18-19 September 2025, Varaždin, Croatia
                  <span></span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </intro>
  );
};
