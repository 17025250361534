import { Image } from "./image";
import React from "react";

export const Venue = (props) => {
  return (
    <div id="venue" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Congress Venue</h2>
        </div>
        <div className="col-md-8 col-md-offset-2 text-justify-xs">
          {" "}
          <p>
            The congress will be hosted by The Faculty of Organization and
            Informatics in Varaždin in the newly renovated{" "}
            <a href="https://maps.app.goo.gl/NZ7F9G1F2kKuBLYq6">Vila Oršić</a>,
            located at the following address: Petra Preradovića 15, 42000,
            Varaždin, Croatia.
          </p>
          <p>
            Established in 1962, the Faculty of Organization and Informatics is
            an institution that reaches back more than half a century, which is
            a comparably long tradition when modern technologies studies are
            concerned. Over the decades, the Faculty has been providing
            education to future experts in the field of information sciences and
            technologies, economics, organization, communication and other
            related fields.
          </p>
          <br></br>
          <div className="row">
            <div className="venue-items">
              {props.data
                ? props.data.map((d, i) => (
                    <div
                      key={`${d.title}-${i}`}
                      className="col-sm-6 col-md-4 col-lg-4"
                    >
                      <Image
                        title={d.title}
                        largeImage={d.largeImage}
                        smallImage={d.smallImage}
                      />
                    </div>
                  ))
                : "Loading..."}
            </div>
          </div>{" "}
        </div>
        <div className="col-md-10 col-md-offset-1 section-title">
          <h3>Accommodation</h3>
        </div>
        <div className="col-md-8 col-md-offset-2 text-justify-xs">
          <div className="info-text">
            <p>
              Varaždin offers several dozen establishments for receiving and
              accommodating its guests. Five are hotels and bed & breakfast type
              of accommodation (three of them being 4* establishments), and the
              rest are hostels and family-owned apartments. Most establishments
              are located within walking distance from the congress venue, and
              others are just a few-minute drive away.
            </p>
            <p>
              It is highly recommended to book the accommodation well in
              advance.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
